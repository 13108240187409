export const enum VideoPlayType {
  OnHover = 0,
  /** Available on desktop and Android devices only */
  Autoplay = 1,
  OnClick = 2,
}
export const enum VideoPlaybackSpeed {
  Quarter = 0,
  Half = 1,
  Normal = 2,
  OneAndQuarter = 3,
  OneAndHalf = 4,
  Two = 5,
}

export const enum CategoryLabelType {
  Text = 0,
  Button = 1,
}
